








































































































































import {
  defineComponent, ref, computed, onMounted, useContext, nextTick
} from '@nuxtjs/composition-api';
import { useBoutiques, useConfig, useMap } from "~/composables";
import storeGetters from "~/modules/boutiques/getters/storeGetters";
import { SfCarousel, SfImage, SfLink, SkeletonLoader, SfCarouselItem, SfScrollable, ContactUsForm } from '~/components';

import { usePageStore } from "~/stores/page";
import { CacheTagPrefix, useCache } from "@vue-storefront/cache";
import { useConfigStore } from "~/stores/config";

export default defineComponent({
  name: 'Boutique',
  head() {
    return {
      title: this.$i18n.t('Boutique') as string
    }
  },
  components: {
    ContactUsForm,
    SfCarousel,
    SfImage,
    SfLink,
    SkeletonLoader,
    SfCarouselItem,
    SfScrollable
  },
  setup(_, { refs }) {
    const { addTags } = useCache();
    const { error: nuxtError } = useContext();
    const { loadBoutique, loadingBoutique } = useBoutiques();
    const { createMap, setMarkerCluster, setPosition, fitMarkers } = useMap();
    const { config } = useConfig();
    const { routeData } = usePageStore();
    const landingId= computed(() => routeData.landing_id);
    const boutique = ref(null);
    const sliderItems = ref([]);
    const sliderLength = ref(0);
    const bottomGridItems = ref([]);
    const markers = ref([]);
    const { storeConfig } = useConfigStore();
    const websiteCode = computed(() => storeConfig.website_code);

    const carouselSettings = {
      type: 'carousel',
      rewind: false,
      perView: 1,
      dots: true,
      dotsClass: 'bullets-flat bullets-white bullets-inside',
      controls: false,
      autoplay: 5000,
      animationDuration: 330,
      animationTimingFunc: 'ease-out',
      slidePerPage: false,
    };

    onMounted( async () => {
      const boutiqueData = await loadBoutique( landingId.value);
      boutique.value = storeGetters.getBoutique(boutiqueData.value);

      if (!storeGetters.getName(boutique.value)) {
        nuxtError({ statusCode: 404 });
      } else {
        sliderItems.value = boutique.value['media_gallery']
            .filter(item => item.role === 'slider_image')
          || [];
        sliderLength.value = sliderItems.value.length;

        bottomGridItems.value = boutique.value['media_gallery']
            .filter(item => item.role === 'bottom_grid_image')
          || [];

        const storeLanguage = config.value.locale.slice(0,2);
        nextTick(async () => {
          await createMap(refs.map, storeLanguage,
            {
            center: { lat: +boutique.value['boutiques'][0].address.latitude, lng: +boutique.value['boutiques'][0].address.longitude }
          },
            websiteCode.value === 'togas_at_website'
            ? [{ 'elementType': 'labels.icon', 'stylers': [{'visibility': 'on'},  {'color': '#333333'}, {'lightness': 40}]}]
            : []);
          markers.value = await setMarkerCluster(...[boutique.value['boutiques']]);

          if (markers.value.length > 1) {
            fitMarkers(boutique.value['boutiques']);
          }

          markers.value.forEach((marker) => {
            marker.addListener('click', () => {
              setPosition(marker.position);
            });
          });
        })
      }

      addTags([{ prefix: CacheTagPrefix.View, value: '' }]);
    });

    return {
      carouselSettings,
      boutique,
      loadingBoutique,
      sliderItems,
      sliderLength,
      ...storeGetters,
      bottomGridItems,
    }
  }
})
